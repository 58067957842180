// @ts-nocheck

// import locale_de from 'dayjs/locale/de';
// import locale_fr from 'dayjs/locale/fr';

// export const DAYJS_LOCALES = {
//   da: locale_da,
//   de: locale_de,
//   fr: locale_fr,
// } as { [key: string]: ILocale };

export const currentLocale = () => {
  const locale = localStorage.getItem('v1:duplicati:locale') ?? 'en-US';
  const simpleLocale = locale.split('-')[0];

  if (simpleLocale === 'en') {
    return import('dayjs/locale/en');
  }

  if (simpleLocale === 'da') {
    return import('dayjs/locale/da');
  }

  if (simpleLocale === 'fr') {
    return import('dayjs/locale/fr');
  }

  if (simpleLocale === 'de') {
    return import('dayjs/locale/de');
  }
  // const selectedLocale = DAYJS_LOCALES[simpleLocale];
};
